/* === initial settings === */
* {box-sizing: border-box;}
html {
  font-size: 14px;
  background-color: #252525;
  color: #f1f1f1;
}  
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
  border-collapse: collapse;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif, monospace;
  font-weight: 300;
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {font-weight: 300;}
h1 {font-size: 1.5rem;}
h2 {font-size: 1.2rem;}
h3 {font-size: 1.25rem;margin:0}
h4 {font-size: 1.1rem;}
h5 {font-size: 1.15rem;}
h6 {font-size: 1rem;}

p {font-size: 1rem;}

button {
  padding: .5em 1em;
  background-color: #f1f1f1;
  color: #252525;
}

header {
  min-height: 10vh;
  text-align: left;
  border-bottom: 1px solid grey;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 ;
}
header * {
  margin: 0;
}


header div:nth-child(1) {
/*   outline: 1px solid grey; */
padding: .5rem;
margin:  0;
display: inline-block;
min-height: 10vh;
width: 100%;
}

header div:nth-child(2) {
/*   outline: 1px solid grey; */
padding: .5rem;
min-height: 10vh;
}

header select {
  background-color: #252525;
  color: #f1f1f1;
}

@media (max-width: 600px) {
  header {
    flex-direction: column;
    text-align: left;
  }
/*  title  */
header h1 {
/*   outline: none; */
order: 2;
border: none;
padding: 0;
min-height: auto;
}
/*  slogan  */
header div:nth-child(1) {
  order: 2;
  border: none;
/*   padding: 0; */
  min-height: auto;
}
/*  language selector  */
header div:nth-child(2) {
  order: 1;
  align-self: end;
  border: none;
/*   padding: 0; */
  min-height: auto;
}
}

main {
  min-height: 60vh;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0;
/*   border-bottom: 1px solid grey; */
}
footer {
  min-height: 20vh;
}

article {
/*   margin-top: 3em */
border-bottom: 1px solid grey;
padding: 1em;
}

a:link {
  text-decoration: underline;
  color: #f1f1f1;
}
a:visited {
  text-decoration: underline;
  color: #f1f1f1;
}
a:hover {
  text-decoration: underline;
  color: magenta;
}

/* === layouts === */
.fluidFlex {
  display: flex;
  flex-wrap: wrap;
}

.flexColumn {
  flex-direction: column;
  display: flex;
}


/* === specifics === */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

label {
  margin-right: 1em;
}

.flexColumn input {
  margin-bottom: 1em;
  width: fit-content;
  min-width: 10em;
  background-color: #252525;
  color: #f1f1f1;
}

.ttsInputText {
  width: 100%;
  background-color: #252525;
  color: #f1f1f1;
}
.attention {
  font-weight: 400;
  background-color: greenyellow;
}
.warning {
  background-color:  #FF5F1F;
}

.flexItemsRight {
  width: 100%;
  display: flex;
  justify-content: end;
  
}

.fullWidthInput {
  width: 100%;
  margin-bottom: 1em;
  background-color: #252525;
  color: #f1f1f1;
}

.serviceDescriptionP {
  white-space: pre-line;
}
.serviceDescriptionP:nth-child(n + 3):nth-child(-n + 8)::first-line {
  font-size: 1.2rem;
  font-weight: 400;
}


/* upload file button */
input[type="file"] {
  width: -webkit-fill-available;
  margin-bottom: 2em;
  background-color: #252525;
  color: #f1f1f1;
}
input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}
input[type="file"]::before {
  content: 'Select file';
  display: inline-block;
  background-color: #f1f1f1;
  color: #252525;
  padding: 0.5em;
  border-width: 2px;
  border-style: outset;
  border-color: buttonborder;
  border-image: initial;

}
input[type="file"]:hover::before {
/*   border-color: black; */
}
input[type="file"]:active::before {
  background: #fadffa;
}
/* end upload file button */


.wordBreak {
  word-wrap: break-word;
}
