.slider {
	display: flex;
	align-items: center;
}
.slider label {
	display: flex;
}
.slider input {
	align-self: center;
}
.slider button {
/* 	padding: 0 .5em; */
}