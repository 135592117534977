footer {
	background-color: #252525;
	color: #f1f1f1;
	margin: 0;
/* 	outline: 1px solid grey; */
	padding: 1em;
}
footer section {
	max-width: 1000px;
	margin: 0 auto;
}
