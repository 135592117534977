nav {
	position: sticky;
	top: 0;
	background-color: #252525;
	color: #f1f1f1;
	z-index: 1;
	justify-content: space-between;
	padding: 0;
	border-bottom: 1px solid grey;
}

nav a {
/* 	outline: 1px solid grey; */
border-right: 1px solid grey;
margin:  0;
padding: 1em;
flex: 1 1 200px;
text-align: center;
}

@media (max-width: 600px) {
	nav a {
		padding:0em;
	}
}


