.App {
  text-align: left;
  width: 100%;
  padding: 0;
/*   max-width: 800px; */
  margin: 0;
  min-height: 100vh;
}



