.audioContainer {
	display: flex;
	flex-direction: column;
	flex: 1 1 250px;
	margin-bottom: 2em;
}
audio {
	width: 100%;
}
audio::-webkit-media-controls-panel {}
audio::-webkit-media-controls-mute-button {}
audio::-webkit-media-controls-play-button {}
audio::-webkit-media-controls-timeline-container {}
audio::-webkit-media-controls-current-time-display {}
audio::-webkit-media-controls-time-remaining-display {}
audio::-webkit-media-controls-timeline {}
audio::-webkit-media-controls-volume-slider-container {}
audio::-webkit-media-controls-volume-slider {}
audio::-webkit-media-controls-seek-back-button {}
audio::-webkit-media-controls-seek-forward-button {}
audio::-webkit-media-controls-fullscreen-button {}
audio::-webkit-media-controls-rewind-button {}
audio::-webkit-media-controls-return-to-realtime-button {}
audio::-webkit-media-controls-toggle-closed-captions-button {}
